import React from "react";
import { useState, ReactNode, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import {
  BookOpen,
  LineChart,
  BarChart4,
  EllipsisVertical,
  CalendarDays,
  Library,
  Settings,
  AlignJustify,
} from "lucide-react";

import { useOnClickOutside, useWindowSize } from "usehooks-ts";

import { cn, fromNow } from "../utils/utils";

import axios from "axios";
import useSWR from "swr";

function arrayfetcher<T>(url: string): Promise<T[]> {
  return axios.get(url).then((res) => {
    return res.data as T[];
  });
}

function fetcher<T>(url: string): Promise<T> {
  return axios.get(url).then((res) => {
    return res.data as T;
  });
}

type Book = {
  bookId: number;
  title: string;
  type: number;
};

const rootURL = process.env.REACT_APP_API_URL.trim();

const Books = () => {
  const {
    data: books,
    error,
    isLoading,
    mutate,
  } = useSWR(`${rootURL}/poems/books`, arrayfetcher<Book>);

  return (
    <div className="flex justify-center max-h-[calc(100vh - 100px)] w-screen text-2xl  dark:bg-gray-800">
      <div className="w-1/2">
        <div className={`grid grid-cols-1 lg:grid-cols-2 gap-4 p-4`}>
          {books?.map((e, i) => (
            <Link to={`/card?bookId=${e.bookId}&cardType=${e.type}`} title="">
              <div
                key={i}
                className={`cursor-pointer border text-gray-800 dark:text-white border-black dark:border-white p-4 text-center `}
              >
                {e.title}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Books;
