import React from "react";
import { useState, ReactNode, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import {
  BookOpen,
  LineChart,
  BarChart4,
  EllipsisVertical,
  CalendarDays,
  Library,
  Settings,
  AlignJustify,
} from "lucide-react";
import { useLocalStorage } from "usehooks-ts";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";

import { cn, fromNow } from "../utils/utils";

import axios from "axios";
import useSWR from "swr";

function arrayfetcher<T>(url: string): Promise<T[]> {
  return axios.get(url).then((res) => {
    return res.data as T[];
  });
}

function fetcher<T>(url: string): Promise<T> {
  return axios.get(url).then((res) => {
    return res.data as T;
  });
}

type StudyCard = {
  answer: string;
  bookCardIndex: number;
  bookId: number;
  createdAt: Date;
  dueTime: Date;
  expectedTime: number;
  id: number;
  lastTime: Date | null;
  previousLine: string;
  question: string;
  type: number;
  updatedAt: Date;
};

type Poem = {
  authorId: number;
  authorName: string;
  comment: string;
  content: string;
  contentPinyin: string | null;

  subTitle: string;
  title: string;
  titlePinyin: string | null;
  updatedAt: Date;
};

type BookStats = {
  alreadyLearnedPoem: number;
  bookName: string;
  needToLearnPoem: number;
};


type PoemAndCard = {
  card: StudyCard;
  poem: Poem;
};


const removeCommentTag = (line: string) => {
  let result = line;
  result = result.replace(/\[.*?\]/g, "");
  result = result.replace(/\〔.*?\〕/g, "");

  return result;
};

const rootURL = process.env.REACT_APP_API_URL.trim();

console.log("root url on card page", rootURL);

const SelectAction = ({ onSelect, isOpen }) => {
  return (
    <div className="relative max-h-[calc(100vh - 100px)]">
      {/* Button to trigger the modal */}

      {/* The modal component */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={() => onSelect(-1)}
          ></div>
          <div className="bg-white rounded-lg p-8 relative z-50 w-full lg:w-[400px]">
            <h2 className="text-xl font-bold mb-4">选择功能</h2>
            <p
              className="mb-6"
              onClick={() => {
                onSelect(0);
              }}
            >
              1 重置背诵卡
            </p>
            <p
              className="mb-6"
              onClick={() => {
                onSelect(1);
              }}
            >
              2 修改背诵顺序
            </p>
            <p
              className="mb-6"
              onClick={() => {
                onSelect(2);
              }}
            >
              3 删除本句
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const OrderByAction = ({ onSelect, isOpen }) => {
  return (
    <div className="relative">
      {/* Button to trigger the modal */}

      {/* 第一个div占满了空白的背景（也就是对话框的外部）看起来就是点击 */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center ">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={() => onSelect(-1)}
          ></div>
          <div className="bg-white rounded-lg p-8 relative z-50 w-full lg:w-[400px]">
            <h2 className="text-xl font-bold mb-4">选择背诵顺序</h2>
            <p
              className="mb-6"
              onClick={() => {
                onSelect(1);
              }}
            >
              1 从前往后背诵
            </p>
            <p
              className="mb-6"
              onClick={() => {
                onSelect(2);
              }}
            >
              2 从后往前背诵
            </p>
            <p
              className="mb-6"
              onClick={() => {
                onSelect(3);
              }}
            >
              3 随机背诵
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

function ModalExample({ isOpen, onClose, onOk }) {
  //const [isOpenInternal, setIsOpen] = useState(isOpen);

  return (
    <div className="relative">
      {/* Button to trigger the modal */}

      {/* The modal component */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black opacity-40"
            onClick={onClose}
          ></div>
          <div className="bg-white rounded-lg p-8 relative z-50 w-full lg:w-[400px]">
            <h2 className="text-xl font-bold mb-4">重置背诵卡</h2>
            <p className="mb-6">确定要把这本书的背诵卡恢复到初始状态吗？</p>

            <div className="justify-between flex">
              <button onClick={onOk} className="  py-2 px-4 rounded">
                确定
              </button>

              <button onClick={onClose} className="   py-2 px-4 rounded">
                取消
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Card = () => {
  const [orderByClause, setOrderByClause] = useLocalStorage("orderByClause", 1);

  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const bookId = params.get("bookId");
  const cardType = params.get("cardType");

  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [bgTime, setBgTime] = useState<number | null>(null);
  const [shake, setShake] = useState(false);
  const [bgColor, setBgColor] = useState("rgba(255, 0, 0, 0)"); // 初始背景色为透明
  const [answer, setAnswer] = useState<string>("xxxxxxx");
  const [clickCount, setClickCount] = useState(0);
  const [clickIndexs, setClickIndexs] = useState<number[]>([]);
  const [newAnswer, setNewAnswer] = useState("");
  const [editAnswer, setEditAnswer] = useState(false);
  const [openResetDlg, setOpenResetDlg] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [openOrderby, setOpenOrderby] = useState(false);

  //注意： 本地服务器，不能用localhost
  const {
    data: studycards,
    error,
    isLoading,
    mutate,
  } = useSWR(
    `${rootURL}/poems/card/next?bookId=${bookId}&cardType=${cardType}&orderBy=${orderByClause}`,
    fetcher<StudyCard>,
  );

  const {
    data: bookStats,
    error: bookStatsError,
    isLoading: bookStatsLoading,
    mutate: bookStatsMutate,
  } = useSWR(
    `${rootURL}/poems/bookstats?bookId=${bookId}&cardType=${cardType}`,
    fetcher<BookStats>,
  );
  
  
   const {
    data: poemAndCard,
    error: poemAndCardError,
    
    mutate: poemAndCardMute,
  } = useSWR(`${rootURL}/poems/bycard?cardId=${studycards?.id}`, fetcher<PoemAndCard>);

  const getColoredPreviousLine = (line: string, keyword: string[]) => {
    let result = line;
    const colors = [
      "rgb(74 222 128)",
      "blue",
      "orange",
      "red",
      "brown",
      "yellow",
      "green",
    ];
    keyword.forEach((e, i) => {
      result = result.replace(
        e,
        `<span style="color: ${colors[i]}">${e}</span>`,
      );
    });

    result = result.replace(/\[.*?\]/g, "");
    result = result.replace(/\〔.*?\〕/g, "");

    return result;
  };

  useEffect(() => {
    if (studycards) {
      setTimeLeft(studycards.expectedTime);
      setBgTime(studycards.expectedTime * 10);
      studycards.answer.split("").length == 7
        ? setAnswer("xxxxxxx")
        : setAnswer("xxxxx");

      setNewAnswer(studycards.answer);
      setClickCount(0);
      setBgColor(`rgba(255, 0, 0, 0)`);
      setClickIndexs([]);
      setShake(false);
    }
  }, [studycards]);

  useEffect(() => {
    // 如果 count 已经是 0，直接返回，不设置计时器
    if (timeLeft === 0) return;

    // 设置计时器
    const intervalId = setInterval(() => {
      setTimeLeft((prevCount) => {
        // 如果当前计数是 0，则停止计时器
        if (prevCount <= 1) {
          clearInterval(intervalId);

          return 0;
        }
        // 否则，减少计数

        return prevCount - 1;
      });
    }, 1000); // 每秒执行一次

    // 清除计时器
    return () => clearInterval(intervalId);
  }, [timeLeft]); // 依赖数组中包含 count，以便在 count 更新时重新运行 effect

  useEffect(() => {
    // 如果 count 已经是 0，直接返回，不设置计时器
    if (timeLeft === 0) return;

    // 设置计时器
    const intervalId = setInterval(() => {
      setBgTime((prevCount) => {
        // 如果当前计数是 0，则停止计时器
        if (prevCount <= 1) {
          clearInterval(intervalId);
          return 0;
        }
        // 否则，减少计数
        return prevCount - 1;
      });
    }, 100); // 每秒执行一次

    // 清除计时器
    return () => clearInterval(intervalId);
  }, [bgTime]);

  useEffect(() => {
    // 计算背景颜色的透明度
    if (bgTime) {
      const opacity = 1 - bgTime / (15 * 10);
      //setBgColor(`rgba(255, 0, 0, ${opacity})`);
    }
  }, [bgTime]);

  const clickAnswer = (e: string, clickAt: number) => {
    if (clickCount >= 7) return;
    setClickCount((e) => e + 1);

    const arr = answer.split("");

    arr[clickCount] = e;

    clickIndexs.push(clickAt);

    console.log("fdsafdsafd", clickIndexs);

    setClickIndexs([...clickIndexs]);
    setAnswer(arr.join(""));
  };

  const clearAnswer = () => {
    studycards.answer.split("").length == 7
      ? setAnswer("xxxxxxx")
      : setAnswer("xxxxx");
    setClickCount(0);

    setClickIndexs([]);
  };

  const updateCard = async () => {
    const cardId = studycards?.id;

    await fetch(
      `${rootURL}/poems/card/update?cardId=${cardId}&answer=${newAnswer}`,
      {
        method: "POST",
      },
    );

    setEditAnswer(false);

    bookStatsMutate();
    mutate();
  };

  const submitAnswer = async () => {
    const cardId = studycards?.id;
    const result = answer == studycards?.answer;

    if (!result) {
      setBgColor(`rgba(255, 0, 0, 1)`);
      setShake(true);
    }

    await fetch(
      `${rootURL}/poems/card/answer?cardId=${cardId}&result=${result}&finishedTime=12`,
      {
        method: "POST",
      },
    );

    //答错则显示诗歌进行学习
    //否则获取下一句
    if (!result) {
      navigate(`/poem?cardId=${cardId}&cardType=${cardType}`);
    } else {
      mutate();
      bookStatsMutate();
    }
  };

  const submitAnswerForComment = async (result: boolean) => {
    const cardId = studycards?.id;

    await fetch(
      `${rootURL}/poems/card/answer?cardId=${cardId}&result=${result}&finishedTime=12`,
      {
        method: "POST",
      },
    );

    mutate();
	setEditAnswer(false)
    bookStatsMutate();
  };

  /*
  <div className="flex">
		   <div>{bookStats?.bookName} </div>
		   <div>{bookStats?.alreadyLearnedPoem}/{bookStats?.needToLearnPoem}</div>
		</div>
  */
  function truncateString(str, length) {
    return str?.length > length ? str?.slice(0, length) + "..." : str;
  }

  const handleChange = (event) => {
    setNewAnswer(event.target.value);
  };

  const onClose = () => {
    setOpenResetDlg(false);
  };

  const resetCard = async () => {
    const r = await fetch(
      `${rootURL}/poems/card/reset?bookId=${studycards.bookId}&cardType=${cardType}`,
      {
        method: "POST",
      },
    );

    setOpenResetDlg(false);
    mutate();
    bookStatsMutate();

    console.log("重置背诵卡", r);
  };

  const deleteCard = async () => {
    const r = await fetch(
      `${rootURL}/poems/card/delete?cardId=${studycards.id}`,
      {
        method: "POST",
      },
    );

    mutate();
    bookStatsMutate();
  };

  return (
    <div className="flex justify-center h-screen w-screen text-2xl">
      <div
        className={`p-5 rounded-lg ${shake ? "shake" : ""}`}
        style={{ backgroundColor: bgColor }}
      >
        <div className="px-4 flex justify-between mb-5">
          <div className="text-green-400 underline underline-offset-8">
            <Link to={`/`} title="">
              {truncateString(bookStats?.bookName, 6)}
            </Link>
          </div>

          <div>
            {" "}
            <span className="text-green-300">
              {bookStats?.alreadyLearnedPoem}{" "}
            </span>{" "}
            /{" "}
            <span
              className="text-orange-300"
              onClick={() => setOpenSelect(true)}
            >
              {bookStats?.needToLearnPoem}{" "}
            </span>
          </div>
        </div>

        <SelectAction
          isOpen={openSelect}
          onSelect={(i) => {
            console.log("select item", i);
            if (i == 0) {
              setOpenResetDlg(true);
            } else if (i == 1) {
              setOpenOrderby(true);
            } else if (i == 2) {
              deleteCard();
            }

            setOpenSelect(false);
          }}
        />

        <OrderByAction
          isOpen={openOrderby}
          onSelect={(i) => {
            setOrderByClause(i);
            setOpenOrderby(false);
          }}
        />

        <ModalExample
          isOpen={openResetDlg}
          onClose={onClose}
          onOk={() => resetCard()}
        />
        {cardType === "2" && (
          <div className="text-center mb-5">
            请从宫格中选择以下诗句的下一句:
          </div>
        )}

        {cardType === "3" && (
          <div className="text-center mb-5">请从宫格中选择合适的诗句:</div>
        )}

        {cardType === "4" && (
          <div className="text-center mb-5">理解下列着色词的意思:</div>
        )}
		
		
        {cardType === "5" && (
          <div className="text-center mb-5">下列诗句的作者是谁:</div>
        )}

        <div
          className="text-4xl text-center mb-5 font-songti"
          onClick={() => {
            setEditAnswer(!editAnswer);
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html:
                studycards?.previousLine &&
                getColoredPreviousLine(
                  studycards?.previousLine,
                  studycards?.question.split(" "),
                ),
            }}
          />
        </div>

        {cardType == "2" && editAnswer && (
          <div className="w-full p-2 text-center">
            <input
              type="text"
              value={newAnswer}
              onChange={handleChange}
              placeholder=""
            ></input>
          </div>
        )}

        {(cardType == "2" || cardType == "3") && (
          <div
            className=" text-4xl text-center mb-5 font-songti"
            onClick={() => {
              setEditAnswer(!editAnswer);
            }}
          >
            {answer.split("").map((e) => (
              <span className="">{e == "x" ? "" : e}</span>
            ))}

            {clickCount < studycards?.answer.length && (
              <span className="blinking-underline text-gray-800 text-opacity-0">
                天
              </span>
            )}
          </div>
        )}

        {/* answer && (
          <div
            className={`h-20 grid ${answer.split("").length == 7 ? "grid-cols-7" : "grid-cols-5"} gap-4 p-4 text-4xl  text-center mb-5 `}
          >
            {answer.split("").map((e, i) => (
              <div
                key={i}
                className={` border border-black p-1 text-center text-opacity-0`}
              >
                {e == "x" ? "" : e}
              </div>
            ))}
          </div>
        )*/}

        {(cardType == "2" || cardType == "3") && studycards?.question && (
          <div
            className={`grid ${studycards.question.split("").length == 9 ? "grid-cols-3" : "grid-cols-4"} gap-4 p-4`}
          >
            {studycards.question.split("").map((e, i) => (
              <div
                key={i}
                className={`cursor-pointer border text-gray-800 border-black p-4 text-center ${clickIndexs.indexOf(i) > -1 ? "text-opacity-0" : "text-opacity-100"}`}
                onClick={() => clickAnswer(e, i)}
              >
                {e}
              </div>
            ))}
          </div>
        )}
		
		 {cardType == "5" && studycards?.question && (
          <div className="text-center text-4xl"  onClick={() => {
              setEditAnswer(!editAnswer);
            }}>
            {studycards.question}
          </div>
        )}
		

        {(cardType == "4" || cardType =="5") && editAnswer && studycards?.answer && (
          <div className="mt-5 text-green-400">
            {studycards.answer.split("\n").map((e, i) => (
              <div>{e}</div>
            ))}
          </div>
        )}
		
		{
		editAnswer &&  poemAndCard && <div className="mt-5">
		<div>{`《`}{poemAndCard?.poem?.title}{`》`}</div>
		{removeCommentTag(poemAndCard?.poem?.content)}
		</div>
		}
		
		
       
      </div>

      {(cardType == "2" || cardType == "3") && !editAnswer && (
        <div className="lg:w-1/2 w-full  p-4 flex fixed bottom-[100px]">
          <button className="btn btn-secondary w-2/5" onClick={submitAnswer}>
            提交
          </button>
          <div className="w-1/5"></div>
          <button className="btn btn-secondary w-2/5" onClick={clearAnswer}>
            重选
          </button>
        </div>
      )}

      {cardType == "2" && editAnswer && (
        <div className="lg:w-1/2 w-full  p-4 flex fixed bottom-[100px]">
          <button className="btn btn-secondary w-2/5" onClick={updateCard}>
            保存
          </button>
          <div className="w-1/5"></div>
          <button
            className="btn btn-secondary w-2/5"
            onClick={() => {
              setEditAnswer(false);
              setNewAnswer(studycards.answer);
            }}
          >
            取消
          </button>
        </div>
      )}

      { (cardType == "4" || cardType == "5")  && (
        <div className="lg:w-1/2 w-full  p-4 flex fixed bottom-[100px]">
          <button
            className="btn btn-secondary w-2/5"
            onClick={() => submitAnswerForComment(true)}
          >
            懂了
          </button>
          <div className="w-1/5"></div>
          <button
            className="btn btn-secondary w-2/5"
            onClick={() => submitAnswerForComment(false)}
          >
            不懂
          </button>
        </div>
      )}
    </div>
  );
};

export default Card;
