import { ReactNode } from "react";

type RootProps = {
  children: ReactNode;
};

const Root = ({ children }: RootProps) => {
  return (
    <div className=" overflow-none">
      {/**
       * 设置最大高度,这样就可以让滚动条只和右边的内容相关
       * 不会影响到左边
       */}
      <div className="flex w-full h-[calc(100vh - 100px)]  overflow-y-auto">
        {children}
      </div>
      
      <footer className="items-center p-4 footer fixed  bottom-0 bg-neutral text-neutral-content h-[100px]">
        <div className="items-center grid-flow-col">
          <img src="/favicon.ico" alt="学习娃工作室" width="32" height="32" />
          <p className="text-purple-200">© 2024 学习娃工作室</p>
          <img src="/gongan.png" alt="学习娃工作室" width="24" height="24" />
          <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402005484' target="_blank">粤公网安备 44030402005484号</a>
          <a

            href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022016022号-1</a>
        </div>


        <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
          <a target="_blank">意见反馈</a>
          <a target="_blank">声明</a>
        </div>
      </footer>
    </div>
  );
};

export default Root;
