import React from "react";
import { useState, useEffect } from "react";
import "./App.css";

import Card from "./pages/card";
import Poem from "./pages/poem";
import Books from "./pages/books";
import Search from "./pages/search";
import Root from "./components/root";

import {
  BrowserRouter, //在electron中不能用BrowserRouter， 否则在electron中path是  d:/dashboard 这样的，
  HashRouter, //在浏览器里中不能用hashrouter, 否则/test这样不会匹配到组件
  Routes,
  Route,
  Link,
} from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/card"
            element={
              <Root>
                <Card />
              </Root>
            }
          />
          <Route
            path="/poem"
            element={
              <Root>
                <Poem />
              </Root>
            }
          />
          <Route
            path="/search"
            element={
              <Root>
                <Search />
              </Root>
            }
          />
          <Route
            path="/"
            element={
              <Root>
                <Books />
              </Root>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
