import React from "react";
import { useState, ReactNode, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import {
  BookOpen,
  LineChart,
  BarChart4,
  EllipsisVertical,
  CalendarDays,
  Library,
  Settings,
  AlignJustify,
} from "lucide-react";

import { useOnClickOutside, useWindowSize } from "usehooks-ts";

import { cn, fromNow } from "../utils/utils";

import axios from "axios";
import useSWR from "swr";

function arrayfetcher<T>(url: string): Promise<T[]> {
  return axios.get(url).then((res) => {
    return res.data as T[];
  });
}

function fetcher<T>(url: string): Promise<T> {
  return axios.get(url).then((res) => {
    return res.data as T;
  });
}

type SearchResult = {
  poem: {
    id: number;
    authorId: number;
    authorName: string;
    title: string;
    subTitle: string;
    content: string;
  };
  book: {
    author: string;
    title: string;
    version: string;
  };
  line: string;
  poemIndex: number;
  nextLine: string;
};

const rootURL = process.env.REACT_APP_API_URL.trim();

const Search = () => {
  const [query, setQuery] = useState<string | null>(null);

  const {
    data: books,
    error,
    isLoading,
    mutate,
  } = useSWR(`${rootURL}/poems/query?q=${query}`, arrayfetcher<SearchResult>);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const search = () => {
    mutate();
  };

  return (
    <div className="flex  justify-center  h-screen w-screen text-2xl  dark:bg-gray-800">
      <div className="w-full p-2 text-center border-red-100 mb-5 fixed top-2">
        <input
          type="text"
          value={query}
          onChange={handleChange}
          placeholder="请输入诗句"
        ></input>
      </div>

      <div>
        <div className="mt-20">
          {query &&
            books?.map((e) => (
              <div className="mb-5 bg-gray-200 p-5 rounded">
                <div>{e.line}</div>
                <div>标题： {e.poem?.title}</div>
                <div>作者： {e.poem?.authorName}</div>
                <div>书名： 《{e.book?.title}》</div>
                <div>编号： {e.poemIndex}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Search;
