import React from "react";
import { useState, ReactNode, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import {
  BookOpen,
  LineChart,
  BarChart4,
  EllipsisVertical,
  CalendarDays,
  Library,
  Settings,
  AlignJustify,
} from "lucide-react";

import { useOnClickOutside, useWindowSize } from "usehooks-ts";

import { cn, fromNow } from "../utils/utils";

import axios from "axios";
import useSWR from "swr";

function arrayfetcher<T>(url: string): Promise<T[]> {
  return axios.get(url).then((res) => {
    return res.data as T[];
  });
}

function fetcher<T>(url: string): Promise<T> {
  return axios.get(url).then((res) => {
    return res.data as T;
  });
}

type StudyCard = {
  answer: string;
  bookCardIndex: number;
  bookId: number;
  createdAt: Date;
  dueTime: Date;
  expectedTime: number;
  id: number;
  lastTime: Date | null;
  previousLine: string;
  question: string;
  type: number;
  updatedAt: Date;
};

type Poem = {
  authorId: number;
  authorName: string;
  comment: string;
  content: string;
  contentPinyin: string | null;

  subTitle: string;
  title: string;
  titlePinyin: string | null;
  updatedAt: Date;
};



type PoemAndCard = {
  card: StudyCard;
  poem: Poem;
};

type LinePinyinTranslate = {
  line: string;
  pinyin: string;
  translation: string;
};

const removeCommentTag = (line: string) => {
  let result = line;
  result = result.replace(/\[.*?\]/g, "");
  result = result.replace(/\〔.*?\〕/g, "");

  return result;
};

const rootURL = process.env.REACT_APP_API_URL.trim();

console.log("root url on poem page", rootURL);

const Poem = () => {
  const [params, setSearchParams] = useSearchParams();
  const [card, setCard] = useState<StudyCard | null>(null);
  const [poem, setPoem] = useState<Poem | null>(null);
  const [lines, setLines] = useState<string[]>([]);
  const [hidden, setHidden] = useState(false);
  const [showTranslationIndex, setShowTranslationIndex] = useState(-1);
  const [jsonLines, setJsonLines] = useState<LinePinyinTranslate[]>([]);
  const [pinyinLines, setPinyinLines] = useState<string[]>([]);

  const [translation, setTranslation] = useState<string | null>(null);

  const cardId = params.get("cardId");
  const cardType = params.get("cardType");

  const {
    data: poemAndCard,
    error,
    isLoading,
    mutate,
  } = useSWR(`${rootURL}/poems/bycard?cardId=${cardId}`, fetcher<PoemAndCard>);

  useEffect(() => {
    if (poemAndCard) {
      setPoem(poemAndCard.poem);
      setCard(poemAndCard.card);

      let line = poemAndCard.poem.content;
      if (line.includes("[")) {
        try {
          const parsedLine = JSON.parse(line);
          setJsonLines(parsedLine);
          const pinyins = parsedLine.map((e) => e.pinyin).join("");
          line = parsedLine.map((e) => e.line).join("");
          setPinyinLines(pinyins.split(/[，。？；,.]/));
        } catch (e) {
          //可能出错，因为可能有是注解的编号，而不是JSON数组
        }
      }

      setLines(line.split(/[，。？；,.]/).filter(Boolean));
    }
  }, [poemAndCard]);

  const getTextColor = (line: string) => {
    if (line == card.previousLine) {
      return "text-green-300";
    } else if (line == card.answer) {
      return "text-orange-300";
    } else {
      return "";
    }
  };

  const hidePoem = () => {
    setHidden(!hidden);
  };

  const getLineWithPinyin = (line: string, pinyin: string) => {
    const p = pinyin?.trim().split(" ");
    return (
      <ruby>
        {line.split("").map((e, i) => (
          <>
            {e}
            <rt>{p[i]}</rt>
          </>
        ))}
      </ruby>
    );
  };

  const clickLine = (i: number) => {
    console.log("click at", Math.floor(i / 2), jsonLines[0]);

    setTranslation(jsonLines[Math.floor(i / 2)].translation);
  };

  return (
    <div className="flex justify-center  max-h-[calc(100vh - 100px)] w-screen text-2xl">
      <div className="w-full lg:w-1/2 p-2">
        <div className="text-center mb-5 text-3xl">{poem?.title}</div>
        <div className="text-end mb-5">{poem?.authorName}</div>

        <div className=" mb-10 text-4xl leading-loose">
          {lines &&
            lines.map((e, i) => (
              <span
                onClick={() => {
                  clickLine(i);
                }}
                className={`${getTextColor(e)} text-gray-800 ${hidden && i % 2 == 1 ? "text-opacity-0 border bg-pink-200" : "text-opacity-100"}`}
              >
                {getLineWithPinyin(
                  e,
                  pinyinLines?.length > 0 ? pinyinLines[i] : "",
                )}{" "}
                {i % 2 == 1 ? "。" : "，"}
              </span>
            ))}
        </div>

        <div>{translation}</div>

        <div>{poem?.comment}</div>
      </div>

      <div className="w-full lg:w-1/2 p-2  justify-between flex fixed bottom-2">
        <button className="btn btn-secondary w-2/5" onClick={hidePoem}>
          {hidden ? "显示诗句" : "背诵本诗"}
        </button>

        <button className="btn btn-secondary w-2/5">
          <Link
            to={`/card?bookId=${card?.bookId}&cardType=${cardType}`}
            title=""
          >
            背诵其它诗
          </Link>
        </button>
      </div>
    </div>
  );
};

export default Poem;
